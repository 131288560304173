export const APPOINTMENT_DATE = (state) => state.registration.appointmentDate;
export const APPOINTMENT_REQUEST = (state) => state.registration.appointmentRequest;
export const APPOINTMENT_TIME = (state) => state.registration.appointmentTime;
export const DATE_OF_BIRTH = (state) => state.registration.patientData.dateOfBirth;
export const DIGITAL_INTAKE_CHOICE = (state) => state.registration.digitalIntakeChoice;
export const EMAIL = (state) => state.registration.patientData.email;
export const EMPLOYEE_ID = (state) => state.registration.employeeId;
export const FIRST_NAME = (state) => state.registration.patientData.firstName;
export const GENDER = (state) => state.registration.patientData.gender;
export const HEALTH_CARD_NUMBER = (state) => state.registration.patientData.healthCard.number;
export const IS_RETURNING_PATIENT = (state) => state.registration.isReturningPatient;
export const LAST_NAME = (state) => state.registration.patientData.lastName;
export const PHONE = (state) => state.registration.patientData.phone.primary;
export const PRIMARY_COMPLAINT = (state) => state.registration.reasonForVisit;
export const ENROLLED_IN_COMPREHENSIVE_CARE = (state) =>
  state.registration.enrollInComprehensiveCare;
