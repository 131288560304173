<template>
  <div class="d-flex justify-center registration-wrapper" :style="registerWrapperStyle">
    <register-header
      :hideSignIn="$route.meta.hideSignIn"
      v-if="showHeader && !hideHeader"
      :elevate="isScrolled"
    ></register-header>
    <!--
      To make development/ qa easier to test
      Is only visible on dev environments
    -->
    <!-- <div class="dev-info pa-2" v-if="isDev">
      <div class="font-weight-bold">DEVELOPER INFO:</div>
      <div>tenantURL: {{ tenantUrl || 'null' }}</div>
      <div>flow: {{ flow || 'null' }}</div>
      <div>pharmacyId: {{ pharmacyId || 'null' }}</div>
      <div>On Demand: {{ onDemand }}</div>
      <div>Employee Ref: {{ employeeRef || 'null' }}</div>
      <div>custom theme: {{ theme || 'null' }}</div>
    </div> -->
    <div class="registration-content__wrapper" ref="registrationContentWrapper">
      <transition :name="this.$route.meta.disableTransition ? '' : routeTransition" mode="out-in">
        <component
          :is="currentPageComponent"
          :isPageflowV2="true"
          :pageConfig="config"
          @next="goNext"
          @changeOptions="changeOptions"
          ref="currentComponent"
          :type="flowType"
        ></component>
      </transition>
    </div>
    <register-footer
      v-if="showFooter"
      @next="goNext"
      @back="goBack"
      :backDisabled="history.length < 2"
      :isPageflowV2="true"
      :elevate="isScrollable"
    ></register-footer>
    <!-- <rocket-chat v-if="showChat"></rocket-chat> -->
    <pharmacy-referral
      v-if="pharmacyId"
      :footerVisible="showFooter"
      :pharmacyId="pharmacyId"
    ></pharmacy-referral>
  </div>
</template>
<style lang="scss">
.dev-info {
  position: absolute;
  left: 0;
  top: 60px;
}
.registration-wrapper {
  overflow: auto;
  background: var(--v-background-base);
}
.registration-content__wrapper {
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
@media all and (max-width: 960px) {
  .registration-content__wrapper {
    overflow-y: hidden !important;
  }
  .registration-wrapper {
    height: 100%;
  }
}
</style>
<script>
import Vue from 'vue';
import debounce from 'lodash.debounce';
import i18n from '../plugins/i18n';
import { themes, themeCodes } from '../constants/themes';
import { END, REPEAT, REGISTER_PAGE_NAMES } from '../constants/pageflows/variables';
import { NODE_ENV } from '../constants/env';
import { shared } from '../constants/pageflows/models';

import { AUTH_TOKEN } from '../constants/storage';

export default {
  name: 'Register',
  components: {
    RegisterFooter: () => import('../components/RegisterFooter.vue'),
    // RocketChat: () => import('../components/RocketChat.vue'),
    PharmacyReferral: () => import('../components/PharmacyReferral.vue'),
    RegisterHeader: () => import('../components/RegisterHeader.vue'),
  },
  data() {
    return {
      currentPage: null,
      history: [],
      isScrolled: false,
      isScrollable: false,
      pageFlow: null,
      routeTransition: 'component-slide',
    };
  },
  computed: {
    bottomMargin() {
      return this.pharmacyId && this.$vuetify.breakpoint.xsOnly ? '55px' : '';
    },
    config() {
      return this.currentPage?.options?.pageConfig;
    },
    currentPageComponent() {
      const componentName = this.currentPage?.componentName || this.currentPage?.name;
      if (!componentName) return null;
      return () => import(`./${componentName}.vue`);
    },
    employeeRef() {
      return this.$route.query?.e?.split('-') || [];
    },
    flow() {
      return this.$route.params.id || undefined;
    },
    flowType() {
      return this.pageFlow?.type;
    },
    goBackHeader() {
      return this.$store.state.registration.navigation.header.back;
    },

    isDev() {
      return NODE_ENV !== 'production';
    },
    isEndOfFlow() {
      return this.currentPage?.next(this.$store.state) === END;
    },
    isEmbedded() {
      return !!this.$route.query.embed;
    },
    isRegisterError() {
      return this.$store.state.registration.errors.registerError;
    },
    lang() {
      return this.$route.query.lang;
    },
    location() {
      return this.$route.query.location;
    },
    onDemand() {
      return !!this.$route.query.d;
    },
    noIntake() {
      return this.$route.query.noIntake;
    },
    noIntro() {
      return this.$route.query.noIntro;
    },
    patientInfo() {
      try {
        const { pi: patientInfo } = this.$route.query;

        const infos = patientInfo
          .split('%')
          .map((char) => String.fromCharCode(char))
          .join('')
          .replaceAll('\x00', '')
          .split('|');
        const obj = {};
        infos.forEach((i) => {
          const [key, value] = i.split('=');
          try {
            const data = JSON.parse(value);
            obj[key] = data;
          } catch {
            obj[key] = value;
          }
        });
        return obj;
      } catch {
        return null;
      }
    },
    pharmacyId() {
      return this.$route.query.p || null;
    },
    providerId() {
      return this.$store.state.registration.providerId;
    },
    registrationErrorType() {
      return this.$store.state.registration.errors.errorType;
    },
    registerWrapperStyle() {
      const styles = { paddingBottom: '0px', paddingTop: '0px' };
      if (this.$vuetify.breakpoint.smAndDown) {
        if (this.showFooter && this.pharmacyId) {
          styles.paddingBottom = '164px';
        } else if (!this.showFooter && this.pharmacyId) {
          styles.paddingBottom = '72px';
        } else if (this.showFooter && !this.pharmacyId) {
          styles.paddingBottom = '85px';
        }
      } else if (this.showFooter) {
        styles.paddingBottom = '85px';
      }

      if (this.showHeader) {
        styles.paddingTop = this.$vuetify.breakpoint.smAndDown ? '82px' : '125px';
      } else {
        styles.height = '100%';
      }

      if (this.hideHeader) {
        styles.paddingTop = 0;
      }

      styles.minHeight = '100%';

      const customGlobalStyles =
        (themes[this.$store.state.theme] && themes[this.$store.state.theme].customGlobalStyles) ||
        {};
      return { ...styles, ...customGlobalStyles };
    },
    showChat() {
      return this.currentPage?.options?.showChat;
    },
    showFooter() {
      return this.currentPage?.options?.showFooter;
    },
    hideHeader() {
      return !!this.$route.query?.hideHeader;
    },
    tenantUrl() {
      return this.$route.params.tenantUrl || null;
    },
    theme() {
      return this.$route.query.t || this.pageFlow?.theme;
    },
    shouldWarnOnLeave() {
      return this.currentPage?.options?.warnOnLeave;
    },
    showHeader() {
      return this.$vuetify.breakpoint.smAndDown || !this.currentPage?.options?.hideHeader;
    },
  },
  created() {
    this.setLang();
    window.addEventListener('beforeunload', this.leaving);
  },
  methods: {
    changeOptions(options) {
      Object.assign(this.currentPage.options, options);
    },
    leaving(e) {
      if (!this.shouldWarnOnLeave) return;

      e.preventDefault();
      e.returnValue = '';
    },
    getNextPage(currentPage = this.currentPage) {
      if (currentPage?.next(this.$store.state) === REPEAT) {
        return REPEAT;
      }
      if (currentPage?.next(this.$store.state) === END) {
        return END;
      }
      const { pageFlow } = this;
      const { flow } = pageFlow;

      let nextPage;

      /* if no currentPage is falsey, we can assume:
        a) flow has not begun (default currentPage value is null), or
        b) flow is has ended and should now repeat (currentPage argument is null)
      */
      const isFirstPageInFlow = !currentPage;
      if (isFirstPageInFlow) {
        nextPage = flow[pageFlow.landing];
      } else {
        // if we are currently on a page (current page is set)
        // we can assume the next page is the return value of the next() function
        nextPage = flow[currentPage.next(this.$store.state)];
      }
      // determine if the nextPage should be skipped
      if (this.onDemand && nextPage.options.skipOnDemand) {
        return this.getNextPage(nextPage);
      }
      if (!!this.noIntake && nextPage.options.skipOnNoIntake) {
        return this.getNextPage(nextPage);
      }
      if (this.isSignedIn && nextPage.options.skipIfSignedIn) {
        return this.getNextPage(nextPage);
      }
      if (this.noIntro && nextPage.options.skipOnNoIntro) {
        return this.getNextPage(nextPage);
      }
      return nextPage;
    },
    async initRegistration(shouldGoNextPage = false) {
      this.$store.commit('setLoading', true);
      const token = localStorage.getItem(AUTH_TOKEN);
      await this.$store.dispatch('newRegistration', { clearUserData: !this.isEmbedded });
      await this.$store.commit('setRegistrationRoute', this.$route);

      await this.setTenantParams();
      await this.setPageFlow();
      this.setTheme();

      this.setLocation();
      this.setUrlPatientData(token);
      this.$store.commit('setLoading', false);
      if (!shouldGoNextPage) return;
      this.goNext();
    },
    async goBack() {
      if (this.$refs.currentComponent.goBack) {
        this.$refs.currentComponent.goBack();
        return;
      }
      this.routeTransition = 'component-slide-reverse';
      if (this.history.length < 2) return;
      this.history.pop();
      const toPage = [...this.history].pop();
      this.currentPage = this.pageFlow.flow[toPage];
    },
    async goNext(params) {
      this.routeTransition = 'component-slide';
      let nextPage = this.getNextPage();
      if (nextPage === END) return;
      if (nextPage === REPEAT) {
        // Dispatch event for when registration flow is embedded in an iframe
        const event = new CustomEvent('registrationEnd');
        window.parent?.document?.dispatchEvent(event);

        // Clear history
        this.history = [];

        // Repeat flow
        nextPage = this.getNextPage(null);
        await this.initRegistration(false);
      }

      if (nextPage.options.clearHistory) {
        this.history = [];
      } else {
        this.history.push(nextPage.name);
      }

      if (params?.clearHistoryItem) {
        const itemIndex = this.history.indexOf(params.clearHistoryItem);
        if (itemIndex > -1) {
          this.history.splice(itemIndex, 1);
        }
      }

      this.currentPage = nextPage;
      this.$store.commit('setCompleted', false);
    },
    onRegistrationError() {
      const errorPage = this.pageFlow.onError(this.registrationErrorType);
      if (this.currentPage?.name === errorPage || this.history.length < 2) return;

      this.goBack();
      this.onRegistrationError();
    },

    onResize() {
      const registrationElement = this.$refs.registrationContentWrapper;
      const { clientHeight, scrollHeight } = registrationElement;
      this.isScrollable = clientHeight < scrollHeight;
    },
    onScroll(e) {
      this.isScrolled = e.target.scrollTop || document.scrollingElement.scrollTop !== 0;
    },
    setHandlers() {
      const registrationElement = this.$refs.registrationContentWrapper;
      registrationElement.addEventListener('scroll', this.onScroll);
      registrationElement.addEventListener('touchmove', this.onScroll);
    },
    setResizeHandler(count = 0) {
      if (count > 10) return;
      const element = document.querySelector('.form-container');
      if (!element) {
        setTimeout(() => {
          this.setResizeHandler(count + 1);
        }, 500);
        return;
      }
      const resizeObserver = new ResizeObserver(this.onResize);
      resizeObserver.observe(element);
    },
    setLang() {
      if (!this.lang) return;
      this.$store.commit('setRegistration', { lang: this.lang });
      i18n.locale = this.lang;
      this.$vuetify.lang.current = this.lang;
    },
    setLocation() {
      if (!this.location) return;
      this.$store.commit('setLocation', { id: this.location });
    },
    async setPageFlow() {
      // const pageFlows = await import('../constants/pageflows/index');

      const { ENABLED_FLOWS, default: pageFlows } = await import('../constants/pageflows/index');
      const { flow, tenantUrl } = this;
      let pageFlow;
      let name;
      if (flow && pageFlows.global[flow]) {
        pageFlow = pageFlows.global[flow];
        name = flow;
      } else if (pageFlows.tenant[tenantUrl]) {
        pageFlow = pageFlows.tenant[tenantUrl];
        name = tenantUrl;
      } else {
        pageFlow = pageFlows.tenant.default;
        name = 'default';
      }
      const flowIsEnabled = ENABLED_FLOWS.includes(name);
      if (!flowIsEnabled) {
        this.$router.push({ name: 'RegisterDisabled' });
      } else {
        this.pageFlow = pageFlow;
      }
    },

    async setTenantParams() {
      const { employeeRef, pharmacyId, tenantUrl, onDemand } = this;
      await this.$store.commit('setLoading', true);

      if (tenantUrl) {
        await this.$store.dispatch('getProviderId', { tenantUrl });
      }

      await this.$store.commit('setRegistration', { availableEmployees: employeeRef });

      if (pharmacyId) {
        this.$store.dispatch('setPreferredPharmacy', { pharmacyId });
      }

      if (onDemand) {
        this.$store.dispatch('setOnDemand', { onDemand });
      }
      await this.$store.commit('setLoading', false);
    },
    setTheme() {
      if (!this.theme) return;
      if (!themeCodes[this.theme]) return;
      if (!themes[themeCodes[this.theme]]) return;
      this.$vuetify.theme.themes.light = themes[themeCodes[this.theme]];
      this.$store.state.theme = themeCodes[this.theme];
    },
    setGTag() {
      const googleTagManagerScript = document.createElement('script');
      googleTagManagerScript.src = 'https://www.googletagmanager.com/gtag/js?id=UA-168598176-1';
      document.body.appendChild(googleTagManagerScript);

      const dataLayerScript = document.createElement('script');
      dataLayerScript.type = 'text/javascript';

      const code = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'UA-168598176-1');`;

      dataLayerScript.appendChild(document.createTextNode(code));
      document.body.appendChild(dataLayerScript);
    },
    setUrlPatientData(token) {
      if (!this.patientInfo) return;
      const info = { ...this.patientInfo };
      const { query: queryParams } = { ...this.$route };
      delete queryParams.pi;
      this.$router.replace({
        query: {
          ...this.$route.query,
          pi: null,
        },
      });
      this.$store.commit('setPatientData', info);
      if (token) localStorage.setItem(AUTH_TOKEN, token);
    },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
  async mounted() {
    this.setHandlers();
    await this.initRegistration(true);
    this.setGTag();
    /*
      Sets properties for all child components in flow;
    */
    Vue.mixin({
      data() {
        return {
          pageTitle: null,
          pageSubtitle: null,
          debounceUpdateRemainingFields: null,
        };
      },
      props: {
        pageConfig: {
          type: Object,
          required: false,
          default: () => {
            return {};
          },
        },
        // temporary
        isPageflowV2: {
          required: false,
          type: Boolean,
          default: () => {
            return true;
          },
        },
        // type of pageflow, for any shared methods
        type: String,
      },
      computed: {
        isRegisterPage() {
          return REGISTER_PAGE_NAMES.includes(this.$options.name);
        },
        isEmbedded() {
          return !!this.$route.query.embed;
        },
      },
      methods: {
        setPageVariablesFromConfig() {
          const { pageConfig } = this;
          if (!pageConfig) return;
          Object.keys(pageConfig).forEach((option) => {
            if (typeof pageConfig[option] === 'object' && !Array.isArray(pageConfig[option])) {
              Object.assign(this[option], pageConfig[option]);
            } else {
              this[option] = pageConfig[option];
            }
          });
        },
      },
      /*
        All Register pages will initialize on mount.
        Extracts the options from their pageConfig
        and overrides their instance variables
      */
      mounted() {
        if (this.isRegisterPage && this.isPageflowV2) {
          this.setPageVariablesFromConfig();
          if (this.completed !== undefined) {
            this.$store.commit('setCompleted', this.completed);
          }
        }

        this.debounceUpdateRemainingFields = debounce(
          (val) => {
            this.$store.commit('setRemainingFields', [...val]);
          },
          200,
          { trailing: true }
        );

        if (this.type && this.isRegisterPage) {
          try {
            if (shared[this.type][this.$options.name]) {
              const { init, ...rest } = shared[this.type][this.$options.name];
              if (init) init(this.$store, this.$route, this);
              Object.assign(this, rest);
            }

            // eslint-disable-next-line no-empty
          } catch {}
        }

        if (this.isRegisterPage && this.init && typeof this.init === 'function')
          this.init(this.$store, this.$route, this);
      },
      watch: {
        completed(val) {
          if (this.isRegisterPage && this.completed !== undefined && this.isPageflowV2) {
            this.$store.commit('setCompleted', val);
          }
        },
        providerId(val) {
          if (!val) {
            this.$store.dispatch('getProviderId', { tenantUrl: this.tenantUrl });
          }
        },
        remainingFields: {
          deep: true,
          handler(val) {
            if (!this.debounceUpdateRemainingFields) return;
            this.debounceUpdateRemainingFields(val);
          },
        },
      },
    });
  },
  watch: {
    currentPage: {
      deep: true,
      immediate: true,
      handler(currentPage, lastCurrentPage) {
        if (currentPage?.name === lastCurrentPage?.name) return;
        this.$store.commit('setRegistration', { currentPage });
        this.$store.commit('setRemainingFields', []);
        this.$nextTick(() => {
          this.scrollToTop();
          this.setResizeHandler();
        });
      },
    },
    goBackHeader(val) {
      if (val) {
        this.goBack();
      }
    },
    isRegisterError(val) {
      if (val) {
        this.onRegistrationError();
        localStorage.removeItem(AUTH_TOKEN);
      }
    },
    async providerId(val) {
      if (!val) {
        this.$store.commit('setLoading', true);
        await this.$store.dispatch('getProviderId', { tenantUrl: this.tenantUrl });
        this.$store.commit('setLoading', false);
      }
    },
  },
};
</script>
