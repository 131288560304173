export const REGISTER_PAGE_NAMES = [
  'RegisterAssessment',
  'RegisterComplete',
  'RegisterConfirm',
  'RegisterCovidLanding',
  'RegisterFileUpload',
  'RegisterGateway',
  'RegisterGoodbye',
  'RegisterIntroduce',
  'RegisterInform',
  'RegisterInformAbc',
  'RegisterIntakeChoice',
  'RegisterLogout',
  'RegisterNewReturning',
  'RegisterNewReturningTwo',
  'RegisterNotFound',
  'RegisterSchedule',
  'RegisterSignUp',
  'RegisterSurvey',
  'RegisterThankyou',
  'RegisterVisit',
];

export const END = 'end';
export const REPEAT = 'repeat';
