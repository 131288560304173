/* eslint-disable no-param-reassign */
import { END, REPEAT } from './variables';
import { LEAD_LOCATIONS } from '../locations';
import { NODE_ENV } from '../env';
import i18n from '../../plugins/i18n';
import { FIRST_NAME, LAST_NAME, DATE_OF_BIRTH, GENDER, PRIMARY_COMPLAINT } from '../store';

export const defaultOptions = {
  showFooter: true,
  showChat: true,
  skipOnDemand: false,
  skipOnEmployeeRef: false,
  skipIfSignedIn: false,
  warnOnLeave: true,
  pageConfig: {},
};

export const defaultPages = {
  RegisterVisit: {
    name: 'RegisterVisit',
    next: () => 'RegisterSchedule',
    options: {
      ...defaultOptions,
      showFooter: false,
      skipOnDemand: true,
      skipOnEmployeeRef: true,
    },
  },
  RegisterSchedule: {
    name: 'RegisterSchedule',
    next: () => 'RegisterIntroduce',
    options: {
      ...defaultOptions,
      skipOnDemand: true,
    },
  },
  RegisterSurvey: {
    name: 'RegisterSurvey',
    next: () => 'RegisterConfirm',
    options: {
      ...defaultOptions,
      skipOnNoIntake: true,
      showFooter: false,
    },
  },
  RegisterAssessment: {
    name: 'RegisterAssessment',
    next: () => 'RegisterThankyou',
    options: {
      ...defaultOptions,
      showFooter: false,
      warnOnLeave: false,
    },
  },
  RegisterFileUpload: {
    name: 'RegisterFileUpload',
    nextPage: () => REPEAT,
    options: {
      ...defaultOptions,
      pageConfig: {
        leadLocations: LEAD_LOCATIONS,
      },
    },
  },
  RegisterThankyou: {
    name: 'RegisterThankyou',
    nextPage: () => END,
    options: {
      ...defaultOptions,
      showFooter: false,
      warnOnLeave: false,
    },
  },
};

export const shared = {
  cannabis: {
    RegisterIntroduceReturning: {
      init: (store, route, vm) => {
        // eslint-disable-next-line no-param-reassign
        vm.disclaimer = i18n.t('registration.cannabis.registerIntroduce.disclaimer');
      },
    },
    RegisterIntroduce: {
      init: (store, route, vm) => {
        vm.disclaimer = i18n.t('registration.cannabis.registerIntroduce.disclaimer');
      },
    },
    RegisterSchedule: {
      init: (store, route, vm) => {
        if (!store) return;
        const { province } = store?.state?.registration?.patientData?.healthCard || {};
        if (!province) return;
        const availableEmployees =
          route.query?.e?.split('-') ||
          (store?.state?.registration?.employeeId && [store?.state?.registration?.employeeId]) ||
          [];

        if (province === 'ontario') {
          store.commit('setRegistration', { availableEmployees });
          return;
        }

        // 15 minute appointment duration for all non-ontario
        vm.appointmentDuration = 15;

        const tenantUrl = store.state.registration.tenantUrl || localStorage.tenantUrl;
        if (NODE_ENV === 'development' && tenantUrl === 'demo') {
          store.commit('setRegistration', { availableEmployees: ['q2hbLRj9h8Vk6vJsySB3goP9Eao2'] });
        } else if (NODE_ENV === 'qa' && tenantUrl === 'demo') {
          store.commit('setRegistration', { availableEmployees: ['BIJYvfG7Myc3XXLp8REnIIAAyWn2'] });
        } else if (NODE_ENV === 'production' && tenantUrl === 'sentry-health') {
          store.commit('setRegistration', { availableEmployees: ['WHChBhGn7OdSCg8v4BsJAGtOEew2'] });
        }
      },
    },
    RegisterSurvey: {
      pageTitle: i18n.t('registration.cannabis.registerSurvey.pageTitle'),
      pageSubtitle: i18n.t('registration.cannabis.registerSurvey.pageSubtitle'),
      init: async (store, vars, vm) => {
        const { lang = 'en' } = vm.$route?.query || {};
        const { tenantUrl } = store.state.registration;

        const forms = {
          development: {
            demo: {
              en: 'RbcTejYsWqfRecB266yq',
              fr: 'Df8ZfKVLmboSh2GuawPj',
            },
          },
          qa: {
            demo: {
              en: 'OiuBkHCmi63egfpsynB4',
              fr: 'SgZtLn3w3QjMwlMOxXrG',
            },
          },
          production: {
            demo: {
              en: 'GIRLnnzfXo7ZUrsjpLIe',
              fr: '8RUWh3mS6Dq9qHYfWb3j',
            },
            'sentry-health': {
              en: 'nS2B5d4dwVXRVQL4ZGdC',
              fr: 'zDNdsHbKLZc1IDvoRUDh',
            },
          },
        };

        await store.commit('setRegistration', {
          form: forms[NODE_ENV][tenantUrl][lang],
        });
      },
      onSurveyCreate: (state, survey) => {
        survey.data = {
          ...survey.data,
          firstName: FIRST_NAME(state),
          lastName: LAST_NAME(state),
          gender: GENDER(state),
          primaryComplaint: PRIMARY_COMPLAINT(state),
          dateOfBirth: DATE_OF_BIRTH(state),
        };
      },
      customSubmit: (data, store) => {
        const newNoteText = `
        CUD SCREENING:
        ${data.question1?.length ? data.question1.join('\n') : 'None'}
        `;
        store.commit('addOtherPatientNote', { newNoteText, noteTags: ['CUD'] });
      },
      showEndSplash: false,
    },
  },
};
